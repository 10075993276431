import { isMobile } from "../../libs/userAgent";
import 'swiper/swiper-bundle.css'
import 'swiper/swiper.min.css'
import "./index.scss";
import SeriesScreen1 from "./Screen1";
import SeriesScreen2 from "./Screen2";
import NewSeries from "./newScreen";


const Series = (): JSX.Element => {
  return (
    <>
      {
        isMobile()
          ?
          <div className="series">

            <div className="series-content">
              <SeriesScreen1 />
              <SeriesScreen2 />
            </div>
          </div>
          : <NewSeries />
      }
    </>
  );
}

export default Series;
