// import { isMobile } from "../../../libs/userAgent";
import "./index.scss";

const SeriesScreen2 = (): JSX.Element => {
  return (
    <>
      <div className="series-screen-2">
        <div className="series-screen-2-content">
          {/* {
            isMobile()
              ? <div className="series-screen-1-title">
                Exclusive Series
              </div>
              : ''
          } */}
          <div className="series-screen-2-content-subtitle">
            MetaStars
          </div>
          <div className="series-screen-2-content-detail">
            The creative customization is endless. We will bring IPs of designer brands, icons, and celebrities to life with our one of one MetaStars.
          </div>
        </div>
        <div className="series-screen-2-video">
          <video width="100%" poster={require('../../../assets/videoBg/star.jpg').default} muted playsInline autoPlay loop>
            <source src={require('../../../assets/videos/star.mp4').default} type="video/mp4" />
          </video>
          <div className="series-screen-2-video-mask"></div>
        </div>
      </div>
    </>
  );
}

export default SeriesScreen2;
