import BuildImage from "../../assets/images/build-together.png";
import "./index.scss";

const BuildTogether = (): JSX.Element => {
  const goPage = () => {
    window.open('https://docs.google.com/forms/d/e/1FAIpQLSdZLAT-BaW9WiNb3JSgj0nFoIBPwlEvM7lkGlTZe4NXeG7sPg/viewform', '_blank');
  }

  return (
    <>
      <div className="build">
        <div className="build-title">
          Build Together
        </div>
        <div className="build-content">
          <div className="build-content-left">
            <div className="build-content-left-text">
              If you're a brand, company or artist and want to partner up with us, please reach out to us here.
            </div>
            <div className="build-content-left-btn" onClick={goPage}>
              Join Us
            </div>
          </div>
          <div className="build-content-right">
            <img src={BuildImage} alt="Build Together" />
          </div>
        </div>
      </div>
    </>
  );
}

export default BuildTogether;
