import Image from '../../../assets/images/callaboration-1.png';
import { isMobile } from '../../../libs/userAgent';
import "./index.scss";

const SeriesScreen1 = (): JSX.Element => {
  return (
    <>
      <div className="series-screen-1">
        {
          isMobile()
            ? <div className="series-screen-1-title">
              Exclusive Series
            </div>
            : ''
        }

        <div className="series-screen-1-content">
          <div className="series-screen-1-subtitle">
            Collaboration Series
          </div>
          <div className="series-screen-1-content-detail">
            We will partner with designer brands and artists to integrate their design ideas and creativities into NFTs and showcase to more fashion lovers.
          </div>

        </div>
        <div className="series-screen-1-image">
          <img src={Image} alt="Callaboration" />
        </div>
      </div>
    </>
  );
}

export default SeriesScreen1;
