import "./index.scss";

const AboutUs = (): JSX.Element => {
  return (
    <>
      <div className="about-us">
        <div className="about-us-content">
          <div className="about-us-content-title">
            About Us
          </div>
          <div className="about-us-content-subtitle">
            We are on a mission to connect worldwide fashion lovers, brands and communities together to form a road to a decentralized organization.
            MetaFriends is the next-gen NFT fashion toy in the Metaverse.
            They are fully unique, fashionable and interactive.
          </div>
        </div>
        <div className="about-us-video">
          <video id="about-usVideo" poster={require('../../assets/videoBg/about.jpg').default} width="100%" height="auto" autoPlay loop webkit-playsinline="true" playsInline
            x5-video-player-type="h5" x-webkit-airplay="true" muted>
            <source src={require('../../assets/videos/about.mp4').default} type="video/mp4" />
          </video>
        </div>
      </div>
    </>
  );
}

export default AboutUs;
