import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
// import SwiperCore, { Autoplay, EffectCreative } from "swiper";
import 'swiper/swiper-bundle.css';
import 'swiper/swiper.min.css';
import './index.scss';


import { Pagination } from "swiper";
import { useRef, useState } from 'react';
import { isMobile } from '../../libs/userAgent';
import MobileMindMap from './mobile';
import $ from 'jquery'
// SwiperCore.use([Autoplay, EffectCreative]);

const swiperText: { title: string, subtitle: string, desc: string, }[] = [
  {
    title: 'MetaFriends Launch',
    subtitle: 'Original Series & Collaboration Series & MetaStars',
    desc: 'These three different series of MetaFriends will be released in sequence.'
  },
  {
    title: 'MetaFriends Dapp',
    subtitle: 'ERC-998 + Customization & AI',
    desc: 'Our Dapp will be launched in Q3 to empower more utilities for our MetaFriends. Every MetaFriends NFT based on ERC-721 can be burned and upgraded into ERC-998 which can support full customization.'
  },
  {
    title: 'Partnership',
    subtitle: 'Artists & Designer Brands',
    desc: 'We are committed to partnering artists, designer brands and celebrities, turning unique IPs, creative ideas and fancy artworks into innovative NFTs.'
  },
  {
    title: 'Artist Incubator',
    subtitle: 'Fund & Scholarship',
    desc: 'MetaFriends encourages more young artists and art students to show their talents and creativities. The fund will be used to support them to accomplish their art dreams.'
  },
  {
    title: 'Physical World',
    subtitle: 'Merchandise & Offline Pop Toy Store',
    desc: 'MetaFriends will expand the uses of NFTs beyond mere digital art into real-world physical collectibles and pop toys.'
  },
  {
    title: 'Decentralized Community',
    subtitle: 'Dao/ Alpha Community',
    desc: 'MetaFriends are committed to building a community-driven and holder-participated organization. We will ensure the right for early supporters and get more people to engage in the future development of MetaFriends.'
  },
  {
    title: 'Metaverse Ready',
    subtitle: 'Portable & Playable in Multi-Platforms',
    desc: 'Each MetaFriend is given access to additional platforms where you can use it as your custom playable identity in a fully interactive and decentralized community.'
  }
]


const MindMap = (): JSX.Element => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const descOpacity = useRef(1)
  const [opacityVal, setOpacityVal] = useState(1)
  const ActiveIndexChange = (e: any) => {
    descOpacity.current = 0
    setCurrentIndex(e.activeIndex)
    setOpacityVal(descOpacity.current)
    window.requestAnimationFrame(setOpacity);
    $('.timeline .group').find('.pointCircle').eq(e.activeIndex).addClass('active').siblings().removeClass('active')
  }

  const setOpacity = () => {
    descOpacity.current = Math.min(0.008 + descOpacity.current, 1)
    setOpacityVal(descOpacity.current)
    if (descOpacity.current < 1) {
      window.requestAnimationFrame(setOpacity);
    }
  }



  return (
    <>
      {
        isMobile()
          ? <MobileMindMap />
          : <div className="mindmap" id='Mindmap'>
            <div className="mindmap-wrapper">
              <h1 className='mindmap-wrapper-title'>MindMap</h1>
              <Swiper
                slidesPerView={2}
                centeredSlides={true}
                allowTouchMove={true}
                slideToClickedSlide={true}
                modules={[Pagination]}
                speed={500}
                onActiveIndexChange={(e) => { ActiveIndexChange(e) }}
                onSliderMove={(e) => {
                  descOpacity.current = 0
                  setOpacityVal(descOpacity.current)
                }}
                onTransitionEnd={(e) => {
                  window.requestAnimationFrame(setOpacity);
                }}
              >
                {
                  Array(7).fill('').map((numbers, index) =>
                    <SwiperSlide key={index}>
                      <div className="slider-wrapper">
                        <div className="wrapper-media">
                          <img src={require(`../../assets/images/mindmap/mindmap_0${index + 1}.png`).default} alt="" />
                        </div>
                      </div>
                    </SwiperSlide>
                  )
                }
              </Swiper>
            </div>
            <div className="mindmap-wrapperDesc">
              <div className="description" style={{ opacity: opacityVal }}>
                <h3 className="title">{swiperText[currentIndex].title}</h3>
                <h6 className='subtitle'>{swiperText[currentIndex].subtitle}</h6>
                <p>{swiperText[currentIndex].desc}</p>
              </div>
            </div>
            <div className="timeline">
              <svg width="100%" height="100%" viewBox="0 0 1922 220" version="1.1" >
                <title>mindmap_line</title>
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g>
                    <g className='group'>
                      <path d="M0,135 L415,37.9012346 L524,97 L902.5,37 L1135,86 L1441.5,52 L1666.5,128 L1785.5,67.5 L2096,92" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="10,8"></path>
                      <circle className='pointCircle active' stroke="none" fill="#FFFFFF" fillRule="evenodd" cx="417" cy="39" r="6"></circle>
                      <circle className='pointCircle' stroke="none" fill="#FFFFFF" fillRule="evenodd" cx="526" cy="96" r="6"></circle>
                      <circle className='pointCircle' stroke="none" fill="#FFFFFF" fillRule="evenodd" cx="902" cy="37" r="6"></circle>
                      <circle className='pointCircle' stroke="none" fill="#FFFFFF" fillRule="evenodd" cx="1135" cy="86" r="6"></circle>
                      <circle className='pointCircle' stroke="none" fill="#FFFFFF" fillRule="evenodd" cx="1443" cy="52" r="6"></circle>
                      <circle className='pointCircle' stroke="none" fill="#FFFFFF" fillRule="evenodd" cx="1666" cy="127" r="6"></circle>
                      <circle className='pointCircle' stroke="none" fill="#FFFFFF" fillRule="evenodd" cx="1787" cy="68" r="6"></circle>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          </div>
      }
    </>
  );
}

export default MindMap;
