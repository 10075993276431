import { isMobile } from "../../../libs/userAgent";
import "./index.scss";

const Screen1 = (): JSX.Element => {
  return (
    <>
      <div className="innovation-screen-1">
        <div className="innovation-screen-1-content">
          <div className="innovation-screen-1-content-title">
            Interactive &&nbsp;
            {
              isMobile()
                ? ''
                : <br />
            }
            Intelligent
          </div>
          <div className="innovation-screen-1-content-subtitle">
            MetaFriends are interactive fashion toys that come alive the minute you play with them. They can respond to you in a variety of ways, grow smarter, and can know you the longer you interact with them.
          </div>
        </div>
        <div className="innovation-screen-1-video">
          <video width="100%" muted playsInline autoPlay loop poster={require('../../../assets/videoBg/hello.jpg').default} >
            <source src={require('../../../assets/videos/hello.mp4').default} type="video/mp4" />
          </video>
        </div>
      </div>
    </>
  );
}

export default Screen1;
