import { ReactNode, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../../assets/images/logo.png";
import "./index.scss";

interface Props {
  to: string,
  callback: () => void,
  children?: ReactNode,
}

const NavItem = ({ to, callback, children }: Props): JSX.Element => {

  const goToPage = () => {
    const dom = document.getElementById(to);
    if (dom) {
      dom.scrollIntoView({ behavior: "smooth", });
    }
    callback && callback();
  }

  return (
    <li className={`navlist-item`} onClick={goToPage}>
      {children}
    </li>
  );
}

const MobileHeader = (): JSX.Element => {
  const [navBtn, setNavBtnClassNames] = useState('iconUl');
  const [navModel, setNavModelClassNames] = useState('nav-model');

  const changeNavStatus = () => {
    const isActive = navBtn.indexOf('active');
    if (isActive < 0) {
      setNavBtnClassNames('iconUl active');
      setNavModelClassNames('nav-model active');
    } else {
      setNavBtnClassNames('iconUl');
      setNavModelClassNames('nav-model');
    }
  }

  const liClick = () => {
    setNavBtnClassNames('iconUl');
    setNavModelClassNames('nav-model');
  }

  return (
    <>
      <div className="mobile-header">
        <div className="mobile-header-container">
          <div className="mobile-header-container-logo">
            <Link to={'/'}>
              <img src={Logo} alt="Logo" height="100%" />
            </Link>
          </div>
          <div className="mobile-header-container-spec"></div>
          <div className="mobile-header-container-navBtn icon">
            <ul className={navBtn} onClick={changeNavStatus}>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
        </div>
      </div>

      <div className={navModel}>
        <div className="model-content">
          <div className="phoneNav">
            <ul className="navList textList">
              <NavItem to="Home" callback={liClick}>
                <span className="navlist-item-icon home" />
                Home
              </NavItem>
              {/* <NavItem to="/mint" callback={liClick}>
                <span className="navlist-item-icon mint" />
                Mint
              </NavItem> */}
              <NavItem to="NFT" callback={liClick}>
                <span className="navlist-item-icon nft" />
                NFT
              </NavItem>
              <NavItem to="Innovation" callback={liClick}>
                <span className="navlist-item-icon innovation-icon" />
                Innovation
              </NavItem>
              <NavItem to="Mindmap" callback={liClick}>
                <span className="navlist-item-icon mindmap-icon" />
                Mindmap
              </NavItem>
              <a href="https://discord.com/invite/kJzPcKG2SP" target="_blank" rel="noopener noreferrer">
                <li className="navlist-item">
                  <span className="navlist-item-icon discord social" />
                  Discord
                </li>
              </a>
              <a href="https://twitter.com/MetaFriendsNFT" target="_blank" rel="noopener noreferrer">
                <li className="navlist-item">
                  <span className="navlist-item-icon twitter social" />
                  Twitter
                </li>
              </a>
              <a href="https://opensea.io/collection/metafriendsnft-metaxstudio" target="_blank" rel="noopener noreferrer">
                <li className="navlist-item">
                  <span className="navlist-item-icon opensea social" />
                  Opensea
                </li>
              </a>
              <a href="https://x2y2.io/collection/metafriendsnft-metaxstudio/items" target="_blank" rel="noopener noreferrer">
                <li className="navlist-item">
                  <span className="navlist-item-icon X2Y2 social" />
                  X2Y2
                </li>
              </a>
              <a href="https://looksrare.org/collections/0xC1Ae3FE5f93C2C042FAc7bDcdF94fDD673544e31?queryID=b4a1da3e772e7319805cbde3848b7620&queryIndex=prod_tokens" target="_blank" rel="noopener noreferrer">
                <li className="navlist-item">
                  <span className="navlist-item-icon looksrare social" />
                  Looksrare
                </li>
              </a>
              {/* <a href="https://www.instagram.com/metafriendsnft/" target="_blank" rel="noopener noreferrer">
                <li className="navlist-item">
                  <span className="navlist-item-icon instagram social" />
                  Instagram
                </li>
              </a> */}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default MobileHeader;
