import { ReactNode, useEffect, useRef, useState } from "react";
// import PlayBtn from "../../assets/images/play.png";
// import AboutImage from "../../assets/images/about.png";
import { isMobile } from "../../libs/userAgent";
// import $ from 'jquery';
import "./index.scss";
// import BeforeMint from "../Mint/Before";
// import PreMint from "../Mint/Pre";
// import PublicMint from "../Mint/Public";
import SaleOut from "../Mint/SaleOut";

interface Props {
  onVideoLoaded?: () => void,
  children: ReactNode,
}

const Banner = ({ onVideoLoaded, children }: Props): JSX.Element => {
  const [isRuned, setIsRuned] = useState(false);
  const bannerRef = useRef<HTMLVideoElement>(null);
  // const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const handle = async () => {
      if (!isRuned) {
        if (bannerRef.current) {
          // eslint-disable-next-line
          bannerRef.current.removeEventListener('canplaythrough', handle);
        }

        setIsRuned(true);
        console.log('%c🀁 video loaded', 'color: #ff0000; font-size: 20px;');
        onVideoLoaded && onVideoLoaded();
      }
    }

    if (bannerRef.current) {
      bannerRef.current.addEventListener('canplaythrough', handle)
    }

    return () => {
      if (bannerRef.current) {
        // eslint-disable-next-line
        bannerRef.current.removeEventListener('canplaythrough', handle);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const openVideo = () => {
  //   $('.video_model').fadeIn(500);
  //   if (videoRef.current) {
  //     videoRef.current.play();
  //   }
  // }

  // const closeVideo = () => {
  //   $('.video_model').fadeOut(500);
  //   if (videoRef.current) {
  //     videoRef.current.pause();
  //   }
  // }

  return (
    <>
      <div className="banner" id="Home">
        <div className="banner-mint-wrap">
          {/* <BeforeMint /> */}
          {/* <PreMint /> */}
          {/* <PublicMint /> */}
          <SaleOut />
        </div>
        <div className="banner-video">
          <video ref={bannerRef} id="bannerVideo" poster={isMobile() ? require('../../assets/videoBg/mintMobileBg.jpg').default : require('../../assets/videoBg/mintBg.jpg').default} width={'100%'} height={'100%'} muted playsInline autoPlay loop>
            {/* <source src={require('../../assets/videos/mint-bg.mp4').default} type="video/mp4" /> */}
            {
              isMobile()
                ? <source src={require('../../assets/videos/mintMobileBg.mp4').default} type="video/mp4" />
                : <source src={require('../../assets/videos/mintBg.mp4').default} type="video/mp4" />
            }
          </video>
          <div className="banner-video-mask"></div>
        </div>
        <div className="banner-loading-logo">
          {children}
        </div>
        <div className="banner-content">
          <div className="banner-content-wrap">
            <div className="banner-content-wrap-texts">
              <div className="banner-content-wrap-texts-home">
                {
                  isMobile()
                    ? <div className="banner-content-wrap-texts-home-sologen">
                      The Most Composable <span>&</span>Intelligent
                      <br />
                      NFT Pop Toy
                    </div>
                    : <div className="banner-content-wrap-texts-home-sologen">
                      The Most Composable <span>&</span>
                      <br />
                      Intelligent NFT Pop Toy
                    </div>
                }
              </div>
              {/* <div className="banner-content-wrap-texts-about">
                <div className="banner-content-wrap-texts-about-title">
                  About Us
                </div>
                <div className="banner-content-wrap-texts-about-subtitle">
                  We are on a mission to connect worldwide fashion lovers, brands and communities together to form a road to a decentralized organization where empowers inspirations to burst in different ways.
                </div>
              </div> */}
            </div>
            {/* {
              isMobile()
                ? ''
                : <div className="banner-content-wrap-video" onClick={openVideo}>
                  <img src={AboutImage} alt="About" />

                  <div className="banner-content-wrap-video-play">
                    <img src={PlayBtn} alt="Play" />
                  </div>
                </div>
            } */}
          </div>
        </div>
      </div>
      {/* <div className="mobile-video-box">
        <div className="banner-content-wrap-video" onClick={openVideo}>
          <img src={AboutImage} alt="About" />

          <div className="banner-content-wrap-video-play">
            <img src={PlayBtn} alt="Play" />
          </div>
        </div>
      </div> */}
      {/* <div className="video_model">
        <div className="model_close" onClick={closeVideo}></div>
        <div className="model_content">
          <video ref={videoRef} id="modalVideo" width="100%" height="auto" loop webkit-playsinline="true" playsInline
            x5-video-player-type="h5" x-webkit-airplay="true">
            <source src={require('../../assets/videos/about.mp4').default} type="video/mp4" />
          </video>
        </div>
      </div> */}
    </>
  );
}

export default Banner;
