import './index.scss';

interface Props {
  onConnect: () => void,
}

const ConnectWallet = ({ onConnect }: Props): JSX.Element => {
  return (
    <>
      <div className="connect-v2-connect" onClick={onConnect}>
        Connect Wallet
      </div>
    </>
  );
}

export default ConnectWallet;
