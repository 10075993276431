import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom'
import { cssTransition, ToastContainer } from 'react-toastify';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useRef, useState } from 'react';
import { isMobile } from './libs/userAgent';

import Home from './pages/Home';
import Aos from 'aos';
import { sleep } from './helpers/utilities';
import Header from './pages/Header/PC';
import LogoAnimate from './pages/LogoText';
import Footer from './pages/Footer';
import MobileHeader from './pages/Header/Mobile';
// import MintPage from './pages/Mint';

function App() {
  const SiteRef = useRef<React.LegacyRef<HTMLDivElement> | undefined>();
  const screenRef = useRef<React.LegacyRef<HTMLDivElement> | undefined>();
  const LogoRef = useRef();

  const [isInit, setIsInit] = useState(false);

  const CustomZoom = cssTransition({
    enter: 'Toastify--animate Toastify__zoom-enter',
    exit: 'Toastify--animate Toastify__zoom-exit',
    collapseDuration: 300
  });

  const onVideoLoaded = async () => {
    if (isInit) return;
    setIsInit(true);
    // @ts-ignore
    SiteRef.current.setAttribute('style', 'display: block');
    document.getElementsByClassName('loading-screen-item')[0].classList.add('hide-item');
    await sleep(500);
    console.log('sleep 500ms');
    document.getElementsByClassName('loading-screen-left')[0].classList.add('open-left');
    document.getElementsByClassName('loading-screen-right')[0].classList.add('open-right');
    await sleep(500);

    // @ts-ignore
    screenRef.current.setAttribute('style', 'display: none');
    // @ts-ignore
    LogoRef.current.play();
    await sleep(700);
    document.getElementsByClassName('banner-content-wrap-texts-home')[0].classList.add('show');
    // if (!isMobile()) {
    //   document.getElementsByClassName('banner-content-wrap-video')[0].classList.add('show');
    // }
    await sleep(2000);
    // if (isMobile()) {
    //   document.getElementsByClassName('banner-content-wrap-video')[0].classList.add('show');
    // }
    document.getElementsByClassName('banner-loading-logo')[0].classList.add('hide');
    document.getElementsByClassName('banner-content-wrap-texts-home')[0].classList.remove('show');
    // document.getElementsByClassName('banner-content-wrap-texts-about')[0].classList.add('show');
    // document.getElementsByClassName('loading-logo')[0].setAttribute('style', 'display: none');
    // document.removeEventListener('wheel', stopScroll);

    await sleep(400);
    document.getElementsByClassName('banner-mint-wrap')[0].classList.add('show');

    await sleep(300);

    Aos.refresh();
  }

  const isHomePage = () => {
    const pathName = window.location.pathname;

    return pathName === '/';
  }

  const closeLogo = () => {
    // setTimeout(async () => {
    //   const logoDiv = document.getElementsByClassName('loading-logo')[0];
    //   logoDiv.classList.add('hide');
    //   await sleep(1000);
    //   logoDiv.classList.add('under');
    // }, 1000);
  }

  useEffect(() => {
    if (!isHomePage()) {
      // @ts-ignore
      SiteRef.current.setAttribute('style', 'display: block');
      // @ts-ignore
      screenRef.current.setAttribute('style', 'display: none');
      // document.getElementsByClassName('loading-logo')[0].setAttribute('style', 'display: none');
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      onVideoLoaded();
    }, 3000);
  });

  return (
    <>
      <div ref={screenRef as React.LegacyRef<HTMLDivElement> | undefined} className='loading-screen'>
        <div className='loading-screen-left'></div>
        <div className='loading-screen-right'></div>
        <div className='loading-screen-item'>
          <div className='loading-screen-item-video'>
            <video id="loading-video" width="100%" muted playsInline loop autoPlay>
              <source src={require('./assets/videos/loading.mp4').default} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>

      <div ref={SiteRef as React.LegacyRef<HTMLDivElement> | undefined} className='site'>
        <Router>
          {isMobile() ? <MobileHeader /> : <Header />}
          <Routes>
            <Route path="/" element={<Home onVideoLoaded={onVideoLoaded}><LogoAnimate callback={closeLogo} ref={LogoRef} /></Home>} />
            {/* <Route path="/mint" element={<MintPage />} /> */}
          </Routes>
          <ToastContainer theme="dark" autoClose={2000} hideProgressBar position="top-center" limit={1} transition={CustomZoom} />
          <Footer />
        </Router>
      </div>
    </>
  );
}

export default App;
