import { Tween } from "react-gsap";
import { Controller, Scene } from "react-scrollmagic";
import SeriesScreen1 from "../Screen1";
import SeriesScreen2 from "../Screen2";
import './index.scss'
const NewSeries = (): JSX.Element => {
  return (
    <>
      <div className="newSeries">
        <Controller>
          <Scene triggerHook="onLeave" duration="300%" pin>
            {(progress: number | undefined) => (
              <div className="PinContainer">
                <div className="PinContainer-title">
                  Exclusive Series
                </div>
                <Tween to={{ xPercent: -50 }} totalProgress={progress} paused>
                  <div className="SlideContainer">
                    <Tween duration={0.5}>
                      <div className="flexbox">
                        <div style={{ 'height': '100vh', 'width': '100%' }}>
                          <SeriesScreen1 />
                        </div>
                        <div style={{ 'height': '100vh', 'width': '100%' }}>
                          <SeriesScreen2 />
                        </div>
                      </div>
                    </Tween>
                  </div>
                </Tween>
              </div>
            )}
          </Scene>
        </Controller>
      </div>
    </>
  );
}

export default NewSeries;