import { ReactNode } from "react";
import AboutUs from "../About";
import Banner from "../Banner";
import BuildTogether from "../Build";
import Innovation from "../Innovation";
import MindMap from "../MindMap";
import Original from "../Original";
import PicWall from "../PicWall";
import Series from "../Series";
import "./index.scss";

interface Props {
  onVideoLoaded?: () => void,
  children: ReactNode,
}

const Home = ({ onVideoLoaded, children }: Props): JSX.Element => {
  return (
    <>
      <div className="home">
        <Banner onVideoLoaded={onVideoLoaded} >
          {children}
        </Banner>
        <AboutUs />
        <PicWall />
        <Original />
        <Series />
        <Innovation />
        <MindMap />
        <BuildTogether />
      </div>
    </>
  );
}

export default Home;
