import Logo from "../../assets/images/logo-footer.png";
import { isMobile } from "../../libs/userAgent";
import "./index.scss";

const Footer = (): JSX.Element => {
  return (
    <>
      <div className="footer">
        <div className="footer-top">
          <div className="footer-info">
            <div className="footer-info-logo">
              <a href="https://www.metaxstudio.io/" target="_blank" rel="noopener noreferrer">
                <img src={Logo} alt="Logo" />
              </a>
            </div>
            <div className="footer-info-descript">
              MetaXStudio is an NFT producing company. We use the latest NFT, blockchain and AI technology, combined with manufacturing expertise to create utilized NFTs in the crypto world.
            </div>
          </div>
          {
            isMobile()
              ? <div className="footer-info-right">
                <div className="footer-legal">
                  <div className="footer-title">
                    Legal
                  </div>
                  <div className="footer-item">
                    <a href="./terms.html" target="_blank">
                      Terms of Service
                    </a>
                  </div>
                  <div className="footer-item">
                    <a href="./privacy.html" target="_blank">
                      Privacy Policy
                    </a>
                  </div>
                </div>
                <div className="footer-social">
                  <div className="footer-title">
                    Find Us
                  </div>
                  <div className="footer-item">
                    <a href="https://discord.com/invite/kJzPcKG2SP" target="_blank" rel="noopener noreferrer">
                      Discord
                    </a>
                  </div>
                  <div className="footer-item">
                    <a href="https://twitter.com/MetaFriendsNFT" target="_blank" rel="noopener noreferrer">
                      Twitter
                    </a>
                  </div>
                </div>
              </div>
              : <>
                <div className="footer-legal">
                  <div className="footer-title">
                    Legal
                  </div>
                  <div className="footer-item">
                    <a href="./terms.html" target="_blank">
                      Terms of Service
                    </a>
                  </div>
                  <div className="footer-item">
                    <a href="./privacy.html" target="_blank">
                      Privacy Policy
                    </a>
                  </div>
                </div>
                <div className="footer-social">
                  <div className="footer-title">
                    Find Us
                  </div>
                  <div className="footer-item">
                    <a href="https://discord.com/invite/kJzPcKG2SP" target="_blank" rel="noopener noreferrer">
                      Discord
                    </a>
                  </div>
                  <div className="footer-item">
                    <a href="https://twitter.com/MetaFriendsNFT" target="_blank" rel="noopener noreferrer">
                      Twitter
                    </a>
                  </div>
                </div>
              </>
          }

        </div>
        <div className="footer-copyright">
          Copyright 2021-2022 ©️ MetaFriends. All Rights Reserved
        </div>
      </div>
    </>
  );
}

export default Footer;
