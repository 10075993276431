import { ReactNode } from "react";
import { Link, } from "react-router-dom";
import Logo from '../../../assets/images/logo.png';
import Discord from '../../../assets/icons/discord.png';
import Twitter from '../../../assets/icons/twitter.png';
import Opensea from '../../../assets/images/opensea.png';
import X2Y2 from '../../../assets/icons/x2y2.png';
import Looksrare from '../../../assets/icons/looksrare.png';
import "./index.scss";

interface Props {
  to: string,
  children?: ReactNode,
}

const NavItem = ({ to, children }: Props): JSX.Element => {
  // const navigate = useNavigate();

  // let matched = useMatch({
  //   path: to,
  // });

  const goToPage = () => {
    const dom = document.getElementById(to);
    if (dom) {
      // window.scrollTo(dom.offsetTop, 0);
      dom.scrollIntoView({ behavior: "smooth", });
    }
  }

  return (
    <div className={`header-nav-items-item`} onClick={goToPage}>
      {children}
    </div>
  );
}

const Header = (): JSX.Element => {
  return (
    <>
      <div className="header">
        <div className="header-logo">
          <Link to="/">
            <img src={Logo} alt="logo" />
          </Link>
        </div>
        <div className="header-nav">
          <div className="header-nav-items">
            <NavItem to='Home'>
              <span className="header-nav-items-item-icon icon-home" />
              Home
            </NavItem>
            {/* <NavItem to='/mint'>
              <span className="header-nav-items-item-icon icon-mint" />
              Mint
            </NavItem> */}
            <NavItem to='NFT'>
              <span className="header-nav-items-item-icon icon-NFT" />
              NFT
            </NavItem>
            <NavItem to='Innovation'>
              <span className="header-nav-items-item-icon icon-innovation" />
              Innovation
            </NavItem>
            <NavItem to='Mindmap'>
              <span className="header-nav-items-item-icon icon-mindmap" />
              MindMap
            </NavItem>
          </div>
          <div className="header-nav-socials">
            <div className="header-nav-socials-item">
              <a href="https://discord.com/invite/kJzPcKG2SP" target="_blank" rel="noopener noreferrer">
                <img src={Discord} alt="Discord" />
              </a>
            </div>
            <div className="header-nav-socials-item">
              <a href="https://twitter.com/MetaFriendsNFT" target="_blank" rel="noopener noreferrer">
                <img src={Twitter} alt="Twitter" />
              </a>
            </div>
            <div className="header-nav-socials-item">
              <a href="https://opensea.io/collection/metafriendsnft-metaxstudio" target="_blank" rel="noopener noreferrer">
                <img src={Opensea} alt="Opense" />
              </a>
            </div>
            <div className="header-nav-socials-item">
              <a href="https://x2y2.io/collection/metafriendsnft-metaxstudio/items" target="_blank" rel="noopener noreferrer">
                <img src={X2Y2} alt="x2y2" />
              </a>
            </div>
            <div className="header-nav-socials-item">
              <a href="https://looksrare.org/collections/0xC1Ae3FE5f93C2C042FAc7bDcdF94fDD673544e31?queryID=b4a1da3e772e7319805cbde3848b7620&queryIndex=prod_tokens" target="_blank" rel="noopener noreferrer">
                <img src={Looksrare} alt="Looksrare" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
