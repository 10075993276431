import { forwardRef, useImperativeHandle, useRef } from "react";
import base64Images from "../../libs/base64Image";
import { isMobile } from "../../libs/userAgent";
import "./index.scss";

interface Props {
  callback: () => void,
}

const LogoText = ({ callback }: Props, ref: any): JSX.Element => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useImperativeHandle(ref, () => ({
    play: () => {
      startPlay();
    }
  }));

  const startPlay = async () => {
    if (canvasRef.current) {
      if (isMobile()) {
        canvasRef.current.width = window.innerWidth * 2;
        canvasRef.current.height = window.innerHeight * 2;

        const AR = 1920 / 1080; // w = h*AR
        canvasRef.current.width = window.innerWidth * 2;
        canvasRef.current.height = window.innerWidth * 2 / AR;
      } else {
        canvasRef.current.width = window.innerWidth;
        canvasRef.current.height = window.innerHeight;

        const AR = 1920 / 1080; // w = h*AR
        canvasRef.current.width = window.innerWidth;
        canvasRef.current.height = window.innerWidth / AR;
      }

      const length = base64Images.length;
      for (let i = 0; i < length; i++) {
        await darwImage(base64Images[i]);
      }
      callback && callback();
    }
  }

  const darwImage = (data: string) => new Promise((resolve) => {
    const image = new Image();
    image.src = data;
    setTimeout(() => {
      if (canvasRef.current) {
        const context = canvasRef.current.getContext('2d');
        const AR = 1920 / 1080; // w = h*AR
        context?.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        if (isMobile()) {
          context?.drawImage(image, 0, 0, window.innerWidth * 2, window.innerWidth * 2 / AR);
        } else {
          context?.drawImage(image, 0, 0, window.innerWidth, window.innerWidth / AR);
        }
        resolve(true);
      }
    }, 40);
  });

  return (
    <>
      <div className="logo-text">
        <canvas ref={canvasRef} width={1920} height={1080} />
      </div>
    </>
  );
}

const LogoAnimate = forwardRef(LogoText);

export default LogoAnimate;
