import './index.scss'

const PicWall = (): JSX.Element => {
  return (
    <>
      <div className="picwall" id="NFT">
        <div className="picwall-container">
          <div className="picwall-container_transfrom">
            <div className="picwall-marquee-container ">
              <div className="marqueee marqueee_top">
                {/* <div className="marquee-horizontal-scroll">
                  <div className=" marqueee-imgBox" >
                    {
                      Array(12).fill('').map((numbers, index) =>

                        <div className="imgItem" key={index}><img src={require(`../../assets/picwall/collection${index + 1}.png`).default} alt="" width="100%" /></div>
                      )
                    }
                    {
                      Array(12).fill('').map((numbers, index) =>

                        <div className="imgItem" key={index}><img src={require(`../../assets/picwall/collection${index + 1}.png`).default} alt="" width="100%" /></div>
                      )
                    }
                  </div>
                </div> */}
              </div>
              <div className="marqueee marqueee_center">
                {/* <div className="marquee-horizontal-scroll">
                  <div className=" marqueee-imgBox" >
                    {
                      Array(12).fill('').map((numbers, index) =>

                        <div className="imgItem" key={index}><img src={require(`../../assets/picwall/collection${index + 11}.png`).default} alt="" width="100%" /></div>
                      )
                    }
                    {
                      Array(12).fill('').map((numbers, index) =>

                        <div className="imgItem" key={index}><img src={require(`../../assets/picwall/collection${index + 11}.png`).default} alt="" width="100%" /></div>
                      )
                    }
                  </div>
                </div> */}
              </div>
              <div className="marqueee marqueee_bottom">
                {/* <div className="marquee-horizontal-scroll">
                  <div className=" marqueee-imgBox" >
                    {
                      Array(12).fill('').map((numbers, index) =>

                        <div className="imgItem" key={index}><img src={require(`../../assets/picwall/collection${index + 21}.png`).default} alt="" width="100%" /></div>
                      )
                    }
                    {
                      Array(12).fill('').map((numbers, index) =>

                        <div className="imgItem" key={index}><img src={require(`../../assets/picwall/collection${index + 21}.png`).default} alt="" width="100%" /></div>
                      )
                    }
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="picwall-container_text">
            <div className="picwall-container_text_content">
              MetaFriends is a collection of 10,000 next-gen fashion pop toys in the Metaverse. The whole MetaFriends collection contains 3 series: 1,111 Original MetaFriends, Collaboration Series and MetaStars.
            </div>
          </div>
        </div>
      </div >
    </>
  );
}

export default PicWall;
