import { isMobile } from "../../libs/userAgent";
import "./index.scss";
import 'swiper/swiper-bundle.css'
import 'swiper/swiper.min.css'
import Screen1 from "./Screen1";
import Screen2 from "./Screen2";
import Screen3 from "./Screen3";
import NewInnovation from "./newScreen";
import Screen4 from "./Screen4";


const Innovation = (): JSX.Element => {
  return (
    <>
      {
        isMobile()
          ? <div className="innovation" id="Innovation">
            <div className="innovation-title">
              Innovation
            </div>

            <div className="innovation-content">
              <Screen4 />
              <Screen1 />
              <Screen2 />
              <Screen3 />
            </div>
          </div>

          : <NewInnovation />
      }
    </>
  );
}

export default Innovation;
