import "./index.scss";

const Screen3 = (): JSX.Element => {
  return (
    <>
      <div className="innovation-screen-3">
        <div className="innovation-screen-3-content">
          <div className="innovation-screen-3-content-title">
            Community-Driven
          </div>
          <div className="innovation-screen-3-content-subtitle">
            We are bringing independent artists together to combine their various art creations with our MetaFriends design and build a decentralized community.
          </div>
        </div>
        <div className="innovation-screen-3-video">
          <video width="100%" muted playsInline autoPlay loop poster={require('../../../assets/videoBg/driven.jpg').default}>
            <source src={require('../../../assets/videos/driven.mp4').default} type="video/mp4" />
          </video>
        </div>
      </div>
    </>
  );
}

export default Screen3;
