import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { isMobile } from "../../../libs/userAgent";
import "./index.scss";

const ScreenItem = (props: any, ref: any): JSX.Element => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const noPlay = useRef(true)

  useImperativeHandle(ref, () => ({
    play: () => {
      startPlay();
    },
    stop: () => {
      stopPlay();
    },
    reset: () => {
      resetVideo();
    }
  }));

  const startPlay = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  }

  const stopPlay = () => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
  }

  const resetVideo = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
    }
  }

  useEffect(() => {
    if (!isMobile()) {
      if ((props.time > 0.15 || props.time > 0.85) && noPlay.current) {
        startPlay()
        noPlay.current = false
      }
    }
  }, [props.time])
  useEffect(() => {
    if (!isMobile()) {
      videoRef.current?.addEventListener('ended', function () {
        noPlay.current = true
      }, false);
    }
  })

  return (
    <>
      <div className="innovation-screen-2">
        <div className="innovation-screen-2-content">
          <div className="innovation-screen-2-content-title">
            Fully Customizable
          </div>
          <div className="innovation-screen-2-content-subtitle">
            To increase the utility of full customization, MetaFriends can be burned and upgraded to the ERC-998 standard in the future. Comprises with a body, wearables and accessories, MetaFriends can be customized in different styles.
          </div>
        </div>
        <div className="innovation-screen-2-video">
          <video ref={videoRef} width="100%" poster={require('../../../assets/videoBg/assemble.jpg').default} muted playsInline autoPlay={isMobile() ? true : false} loop={isMobile() ? true : false}>
            <source src={require('../../../assets/videos/assemble.mp4').default} type="video/mp4" />
          </video>
        </div>
      </div>
    </>
  );
}

const Screen2 = forwardRef(ScreenItem);

export default Screen2;
