// import { isMobile } from '../../../libs/userAgent';
import './index.scss';

const Screen4 = (): JSX.Element => {
  return (
    <>
      <div className="innovation-screen-4">
        <div className="innovation-screen-4-content">
          <div className="innovation-screen-4-content-title">
            ERC-721 A+R
          </div>
          <div className="innovation-screen-4-content-subtitle">
            The implementation of this standard will provide minters with a low gas fee and a 7-day refund period which protects holders' rights to a great extent.
          </div>
        </div>
        <div className="innovation-screen-4-video">
          <video width="100%" muted playsInline autoPlay loop poster={require('../../../assets/videoBg/ERC721.jpg').default}>
            <source src={require('../../../assets/videos/ERC721.mp4').default} type="video/mp4" />
          </video>
        </div>
      </div>
    </>
  );
}

export default Screen4;
