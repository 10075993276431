import { useState } from "react";
import SwiperCore, { Autoplay, EffectCreative } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "swiper/swiper-bundle.css"
import { isMobile } from "../../libs/userAgent";
import "./index.scss";

SwiperCore.use([Autoplay, EffectCreative]);

interface RaceDataItem {
  name: string,
  url: string,
  des: string,
}

const RaceData: RaceDataItem[] = [
  {
    name: 'Zombie',
    url: 'Zombie',
    des: 'Foodie and dancer, and love all kinds of brains.'
  },
  {
    name: 'Orc',
    url: 'Orc',
    des: 'Strong and warm-hearted, and passionate about everything.'
  },
  {
    name: 'Devil',
    url: 'Devil',
    des: 'Prank lovers, and no one can touch their horns.'
  },
  {
    name: 'Rabbit',
    url: 'Rabbit',
    des: 'Cute and timid, and really good at handcraft.'
  },
  {
    name: 'Alien',
    url: 'Alien',
    des: 'Tech geeks, pretty shy, and hate rain.'
  },
  {
    name: 'Ape',
    url: 'Ape',
    des: 'Odd temper, and always dress nice.'
  },
  {
    name: 'Cyber Bot',
    url: 'CyberBot',
    des: 'Know everything, and love animals and plants.'
  },
  {
    name: 'Human',
    url: 'Human',
    des: 'Super friendly, hard-working, and emotional.'
  },
]

const Original = (): JSX.Element => {
  const [swiperContral, setswiper] = useState<any>({})
  const [currenntData, setcurrentData] = useState<RaceDataItem>({
    name: 'Human',
    url: 'Human',
    des: 'Super friendly, hard-working, and emotional.'
  });

  return (
    <>
      <div className="original">
        <div className="original-contents">
          <div className="original-contents-title">
            1,111 Originals
          </div>
          <div className="original-contents-subtitle">
            Generated with 8 different races, each Original Metafriend comes with a unique genetic appearance and fashion style. In this series, we have involved artists from all over the world to participate in art design.
          </div>
        </div>

        <div>
          <div className="original-swiper">
            <div className="original-swiper-box"></div>
            <div className="original-swiper-wrap">
              <Swiper
                grabCursor
                effect="creative"
                slidesPerView={isMobile() ? 2 : 3}
                centeredSlides
                autoplay={{ delay: 2000, disableOnInteraction: false }}
                onInit={(el) => {
                  setswiper(el);
                  console.log(swiperContral)
                  const data = RaceData[el.realIndex];
                  setcurrentData(data);
                }}
                loop
                loopedSlides={5}
                allowTouchMove
                slideToClickedSlide
                creativeEffect={{
                  limitProgress: 4,
                  shadowPerProgress: true,
                  perspective: true,
                  prev: {
                    translate: ["-60%", 0, 0],
                    scale: 0.8,
                  },
                  next: {
                    translate: ["60%", 0, 0],
                    scale: 0.8,
                  },
                }}
                onSlideChange={(el) => {
                  const data = RaceData[el.realIndex];
                  if (el.realIndex <= 7) {
                    setcurrentData(data);
                  }
                }}
              >
                {
                  RaceData.map((el, i) => {
                    return <SwiperSlide key={i}>
                      {({ isActive }) => (
                        <div className='original-swiper-content'>
                          <video className='original-swiper-content-video' poster={require(`../../assets/videoBg/${el.url}.jpg`).default} autoPlay width="100%" muted loop playsInline>
                            <source src={require(`../../assets/videos/${el.url}.webm`).default} type="video/webm" />
                            <source src={require(`../../assets/videos/${el.url}.mp4`).default} type="video/mp4" />
                          </video>
                        </div>
                      )}
                    </SwiperSlide>
                  })
                }
              </Swiper>
            </div>
          </div>

          <div className="original-info">
            <div className="original-info-title">
              {currenntData.name}
            </div>
            <div className="original-info-detail">
              {currenntData.des}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Original;
