import { Tween } from "react-gsap";
import { Controller, Scene } from "react-scrollmagic";
import Screen1 from "../Screen1";
import Screen2 from "../Screen2";
import Screen3 from "../Screen3";
import Screen4 from "../Screen4";
import './index.scss'
const NewInnovation = (): JSX.Element => {
  return (
    <>
      <div className="newInnovation" id="Innovation">

        <Controller>
          <Scene triggerHook="onLeave" duration="400%" pin>
            {(progress: number | undefined) => (
              <div className="PinContainer">
                <div className="innovation-title">
                  Innovation
                </div>
                <Tween to={{ xPercent: -75 }} totalProgress={progress} paused>
                  <div className="SlideContainer">
                    <Tween duration={0.5}>
                      <div className="flexbox">
                        <div style={{ 'height': '100vh', 'width': '100%', position: 'relative' }}>

                          <Screen4 />
                        </div>
                        <div style={{ 'height': '100vh', 'width': '100%', position: 'relative' }}>

                          <Screen1 />
                        </div>
                        <div style={{ 'height': '100vh', 'width': '100%', position: 'relative' }}>
                          {/* <div className="innovation-title">
                            Innovation
                          </div> */}
                          <Screen2 time={progress} />
                        </div>
                        <div style={{ 'height': '100vh', 'width': '100%', position: 'relative' }}>
                          {/* <div className="innovation-title">
                            Innovation
                          </div> */}
                          <Screen3 />
                        </div>
                      </div>
                    </Tween>
                  </div>
                </Tween>
              </div>
            )}
          </Scene>
        </Controller>
      </div>
    </>
  );
}

export default NewInnovation;
