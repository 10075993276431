import './index.scss'

const swiperText: { title: string, subtitle: string, desc: string, }[] = [
  {
    title: 'MetaFriends Launch',
    subtitle: 'Original Series & Collaboration Series & MetaStars',
    desc: 'These three different series of MetaFriends will be released in sequence.'
  },
  {
    title: 'MetaFriends Dapp',
    subtitle: 'ERC-998 + Customization & AI',
    desc: 'Our Dapp will be launched in Q3 to empower more utilities for our MetaFriends. Every MetaFriends NFT based on ERC-721 can be burned and upgraded into ERC-998 which can support full customization.'
  },
  {
    title: 'Partnership',
    subtitle: 'Artists & Designer Brands',
    desc: 'We are committed to partnering artists, designer brands and celebrities, turning unique IPs, creative ideas and fancy artworks into innovative NFTs.'
  },
  {
    title: 'Artist Incubator',
    subtitle: 'Fund & Scholarship',
    desc: 'MetaFriends encourages more young artists and art students to show their talents and creativities. The fund will be used to support them to accomplish their art dreams.'
  },
  {
    title: 'Physical World',
    subtitle: 'Merchandise & Offline Pop Toy Store',
    desc: 'MetaFriends will expand the uses of NFTs beyond mere digital art into real-world physical collectibles and pop toys.'
  },
  {
    title: 'Decentralized Community',
    subtitle: 'Dao/ Alpha Community',
    desc: 'MetaFriends are committed to building a community-driven and holder-participated organization. We will ensure the right for early supporters and get more people to engage in the future development of MetaFriends.'
  },
  {
    title: 'Metaverse Ready',
    subtitle: 'Portable & Playable in Multi-Platforms',
    desc: 'Each MetaFriend is given access to additional platforms where you can use it as your custom playable identity in a fully interactive and decentralized community.'
  }
]


const MobileMindMap = (): JSX.Element => {
  return (
    <>
      <div className="mobilemindmap" id='Mindmap'>
        <div className="mobilemindmap-wrapper">
          <div className="mobilemindmap-wrapper-title">MindMap</div>
          <div className="mobilemindmap-wrapper-list">

            {
              swiperText.map((item, index) =>
                <div className="mobilemindmap-wrapper-list_item" key={index}>
                  <div className="wrapper">
                    <div className="wrapper-text">
                      <div className="title">{item.title}</div>
                      <div className="subtitle">{item.subtitle}</div>
                      <div className="desc">{item.desc}</div>
                    </div>
                    <div className="wrapper-media">
                      <img src={require(`../../../assets/images/mindmap/mindmap_0${index + 1}.png`).default} alt="" width='100%' />
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default MobileMindMap;
