import { isMobile } from "../../../libs/userAgent";
import Out1 from '../../../assets/images/out-1.png';
import "./index.scss";
import RefundBtn from "../../../components/Refund";
import { useState } from "react";
import ConnectWallet from "../../../components/ConnectWallet";
import { linkWallet, linkWalletConnect, NxWeb3 } from "../../../libs/NxWeb3";
import QRCodeModal from "@walletconnect/qrcode-modal";
import SelectWalletModal from "../../../components/SelectWallet";
import { showToast } from "../../../libs/utils";

const ByOnOpensea = (): JSX.Element => {
  return (
    <div className="by-btn" onClick={() => { window.open('https://opensea.io/collection/metafriendsnft-metaxstudio', 'target'); }}>
      Buy on Opensea
    </div>
  );
}

const SaleOut = (): JSX.Element => {
  const [isSelectOpened, setIsSelectOpened] = useState(false);
  const [address, setAddress] = useState('');
  const [isConnected, setIsConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const closeModal = () => {
    setIsSelectOpened(false);
  }

  const connectMetaMask = async () => {
    setIsSelectOpened(false);
    const result = await linkWallet(() => {
      setAddress(NxWeb3.instance.accountAddress);
    })
    setIsConnected(result);
    if (NxWeb3.instance.isInited) {
      setAddress(NxWeb3.instance.accountAddress);
    }
  }

  const connectWallet = async () => {
    setIsSelectOpened(false);
    await linkWalletConnect();
    setAddress(NxWeb3.instance.accountAddress);
    subscribe(NxWeb3.instance.WalletConnector);
  }

  const subscribe = async (provider: any) => {
    if (!provider.on) {
      return;
    }
    // provider.on("close", () => resetApp());
    provider.on('connect', async (error: any, payload: any) => {
      if (error) {
        throw error;
      }
      await onWalletConnect(payload);
      QRCodeModal.close();
    });
    provider.on('disconnect', async (error: any, payload: any) => {
      if (error) {
        throw error;
      }
      setAddress('')
      setIsConnected(false);
      NxWeb3.instance.address = '';
      // resetApp();
    });
  };

  const onWalletConnect = (payload: any) => {
    setAddress(payload.params[0].accounts[0])
    setIsConnected(true);
    NxWeb3.instance.address = payload.params[0].accounts[0]
  }

  const airdrop = async () => {
    if (isLoading) return;

    if (!address || !isConnected) {
      showToast('Plase connect wallet', 0);
      return;
    }

    if (NxWeb3.instance.connectType === 1) {
      setIsLoading(true);
      const result = await NxWeb3.instance.airdrop();
      if (result === true) {
        showToast('successed.', 1);
      } else {
        showToast(result.message, 0);
      }
      setIsLoading(false);
    } else {
      setIsLoading(true);
      await NxWeb3.instance.walletAirdrop(() => {
        console.log('successed.');
        showToast('successed.', 1);
      }, (content: string = 'Failed.') => {
        console.log('failed.');
        showToast(content, 0);
      })
      setIsLoading(false);
    }
  }

  return (
    <>
      <div className="sale-out">
        {
          isSelectOpened
            ?
            <SelectWalletModal
              onClose={closeModal}
              onMetaMaskSelected={connectMetaMask}
              onWalletSelected={connectWallet}
            />
            : <></>
        }
        {
          isMobile() ?
            <>
              <div className="sale-out-info">
                Original Total: <span className="sale-out-count-info-count">1,111</span>
                <br />
                Price: <span>0.1ETH</span>
              </div>
              <div className="sale-out-video">
                <video width="100%" muted playsInline autoPlay loop>
                  <source src={require('../../../assets/videos/mintMobileBg.mp4').default} type="video/mp4" />
                </video>
              </div>
              <img className="sale-out-out" src={Out1} alt="out" />
              <div className="sale-out-btn">
                <ByOnOpensea />
                {
                  isConnected && address !== ''
                    ? <>
                      <div className="sale-out-airdrop" onClick={airdrop}>
                        {isLoading ? <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> : <span>Claim</span>}
                      </div>
                      {/* <RefundBtn /> */}
                    </> : <ConnectWallet onConnect={() => { setIsSelectOpened(true) }} />
                }
              </div>
            </> :
            <>
              <video width="100%" muted playsInline autoPlay loop>
                <source src={require('../../../assets/videos/mintBg.mp4').default} type="video/mp4" />
              </video>
              <div className="sale-out-count">
                <div className="sale-out-count-info">
                  Original Total: <span className="sale-out-count-info-count">1,111</span>
                  <br />
                  Price: <span>0.1ETH</span>
                </div>
                <img className="sale-out-out" src={Out1} alt="out" />
                <ByOnOpensea />
                {
                  isConnected && address !== '' ? <div style={{ display: 'flex', alignItems: 'center', }}>
                    {/* <RefundBtn isConnected={isConnected && address !== ''} /> */}
                    <div className="sale-out-airdrop" onClick={airdrop}>
                      {isLoading ? <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> : <span>Claim</span>}
                    </div>
                  </div> : <ConnectWallet onConnect={() => { setIsSelectOpened(true) }} />
                }
              </div>
            </>
        }
      </div>
    </>
  );
}

export default SaleOut;
